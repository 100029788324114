@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

.imagelink {
  display: block;
  margin-bottom: 8px;
}

.image {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  background: rgb(255 255 255 / 10%);
}

.imageSecondary {
  min-width: 100%;
  min-height: 82px;
}

.category {
  font-weight: 600;
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}

.titlelink {
  display: block;
  margin-bottom: 5px;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.15px;

  &:not([class*='CoverList_home'] .title) {
    &:is([class*='CoverList_box']:nth-child(n + 2) .title) {
      @media screen and (max-width: (var.$mid-width - 1)) {
        font-size: clamp(0.75rem, 1vw, 0.875rem);
      }
    }
  }

  &:is([class*='CoverList_home'] .title) {
    font-size: clamp(1rem, 1.5vw, 1.25rem);

    &:is([class*='CoverList_box']:nth-child(n + 2) .title) {
      @media screen and (min-width: var.$tablet-width) {
        font-size: 1rem;
      }
    }
  }
}

.authorblock {
  @include mix.flex($align: 'center', $wrap: 'wrap');

  font-size: 0.6rem;
  line-height: 0.75rem;

  svg {
    height: 20px;
    grid-area: icon;
  }
}

.author {
  grid-area: name;
}

.bullet {
  margin: 0 4px;
}

.time {
  grid-area: time;

  @media screen and (min-width: 768px) {
    margin: 0 4px;
  }
}
