.avatar {
  border-radius: 15px;
  object-fit: cover;
}

.radiusFive {
  border-radius: 5px;
}

.teamAvatar {
  border-radius: 0;
  object-fit: contain;
}
