@use '@on3/styles/variables' as var;

.ad {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  min-height: 250px;
  margin: 3rem auto;

  @media only screen and (min-width: var.$sidebar-break) {
    display: none !important;
  }

  &.incontent {
    position: relative;
    flex-direction: column;
    min-height: 250px;
    text-align: center;
    background-color: var.$lightNeutralGray;
    width: 100%;
    max-width: calc(300px + var.$spacing-sm * 2);
    padding: var.$spacing-sm;

    @media only screen and (min-width: calc(300px + var.$spacing-regular * 4)) {
      max-width: calc(300px + var.$spacing-regular * 2);
      padding: var.$spacing-regular;
    }

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #999;
      font-size: 12px;
      line-height: 16px;
      content: 'ADVERTISEMENT';
    }
  }

  > div {
    z-index: 1;
  }
}
