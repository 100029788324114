@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

.container {
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid var.$lightNeutral;

  @media screen and (min-width: 767px) {
    flex-direction: row;
  }
}

.image {
  border-radius: 5px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  display: block;
  margin-left: 20px;
  min-width: 100px;

  @media screen and (min-width: 767px) {
    min-width: 160px;
    margin-left: 0;
    margin-right: 20px;
    width: 160px;
  }
}

.text {
  flex-grow: 1;
}

.category {
  display: inline-block;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.2;
  text-transform: uppercase;
}

.title {
  font-family: var.$archivo-narrow;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.35px;
  margin: 10px 0;

  @media only screen and (min-width: 767px) {
    font-family: var.$geist;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }
}

.authorblock {
  @include mix.flex($align: 'center', $wrap: 'wrap');

  font-size: 0.6rem;
  line-height: 0.75rem;
  color: var.$gray;
}

.isPremiumMobile {
  display: grid;
  grid-template-areas:
    'name icon'
    'date .';

  @media only screen and (min-width: 767px) {
    display: flex;
    color: var.$gray;
  }
}

.name {
  grid-area: name;
  color: var.$gray;
}

.date {
  grid-area: date;
  color: var.$gray;
}

.bullet {
  margin: 0 4px;
}

.isPremiumBullet {
  display: none;

  @media only screen and (min-width: 767px) {
    display: inline-block;
    margin: 0 4px;
  }
}

.on3Icon {
  margin-left: 10px;
  margin-right: 38px;
  height: 20px;
  grid-area: icon;
}
