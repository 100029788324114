@use '@on3/styles/base';

@use '@on3/styles/variables' as var;

.topcontainer {
  overflow: hidden;
  background-color: var.$focusBackground;

  &:not(.home) {
    .block {
      padding: 1rem 1rem var.$spacing-regular;

      @media screen and (min-width: var.$tablet-width) {
        padding: 1rem var.$spacing-regular 2rem;
      }

      @media screen and (min-width: var.$mid-width) {
        padding: 2rem var.$spacing-regular;
      }
    }
  }
}

.block {
  display: grid;

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: clamp(1.5rem, 3vw, 2rem);
    max-width: var.$full-width;
    margin: 0 auto;

    @media screen and (min-width: var.$mid-width) {
      grid-template-columns: repeat(3, 1fr);
    }

    &:is(.home .container) {
      display: flex;
      gap: var.$spacing-regular;
      padding: 0 1rem;
      scroll-snap-type: x mandatory;

      @extend %horizontal-scroll;

      @media screen and (min-width: var.$tablet-width) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var.$spacing-regular;
        row-gap: var.$spacing-regular;
        white-space: unset;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.box {
  @media screen and (max-width: var.$mid-width - 1) {
    &:first-of-type {
      grid-column: span 2;
    }
  }

  &:is(.home .box) {
    scroll-snap-align: center;
    width: calc(100% - (var.$spacing-regular * 2));
    flex-shrink: 0;

    @media screen and (min-width: var.$tablet-width) {
      width: 100%;
    }

    &:first-of-type {
      grid-column: span 2;
    }

    &:first-of-type article {
      @media screen and (min-width: var.$tablet-width) {
        display: flex;
        gap: var.$spacing-sm;

        [data-article-ui='image'] {
          width: calc(100% * 2 / 3);
          flex-shrink: 0;
        }
      }
    }
  }
}
