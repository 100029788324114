@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.social {
  background-color: var.$white;
  display: grid;
  grid-template-columns: 50px auto;
  width: 100%;
  padding: 0.75rem 0;
  column-gap: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var.$neutral;

  .sitelogo {
    height: 50px;
    width: 50px;
  }

  .image {
    height: 50px;
    width: 50px;
    border-radius: var.$radius-sm;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }

  ul {
    @include mix.spaceY(0.5rem);
  }

  .socialitem {
    display: flex;
    align-items: center;

    svg,
    path {
      fill: var.$gray;
    }

    svg {
      height: auto;
      width: 1rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .link {
    color: var.$gray;
    font-size: 0.75rem;
    margin-left: 0.5rem;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var.$on3primary;
    }
  }
}
