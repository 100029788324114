.ad {
  display: none;

  @media only screen and (min-width: 961px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    min-height: 250px;
    margin: 0 auto;
  }

  &.incontent {
    padding: 10px;
    text-align: center;
    background-color: #f7f7f7;
    flex-direction: column;
    min-height: 270px;
    width: 100%;

    &::before {
      content: 'ADVERTISEMENT';
      margin-bottom: 10px;
      display: block;
      color: #999;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
