@use '@on3/styles/variables' as var;

.layout {
  padding: 0 1rem;

  @media screen and (min-width: var.$tablet-width) {
    padding: 0 var.$spacing-regular;
  }

  .container {
    display: grid;
    grid-template-areas: 'middle' 'left' 'right';
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    gap: var.$spacing-lg;
    max-width: var.$full-width;
    width: 100%;
    margin: 0 auto;
    padding: var.$spacing-regular 0;

    @media screen and (min-width: var.$mid-width) {
      grid-template-areas: 'middle left' 'right left';
      grid-template-columns: 1fr 300px;
    }

    @media screen and (min-width: var.$desktop-width) {
      grid-template-areas: 'left middle right';
      grid-template-columns: 300px 1fr 300px;
      row-gap: 2rem;
      column-gap: var.$spacing-lg;
    }

    [data-ui='ad'] {
      margin: 0 auto;
    }
  }
}
