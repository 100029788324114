@use '@on3/styles/variables' as var;

.block {
  display: grid;
  justify-items: center;
  background: var.$focusBackground;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
}
